<template>
  <div class="login-bg">
    <div class="login-head">
      <div class="logo-holder">
        <div class="logo-holder-inner"
             :style="`background-image: url('${this.apiUrl}/${this.cfg.logoBlack}');`">
        </div>
      </div>
      <div class="logo-project">{{ this.cfg.projectCnName }}</div>
    </div>

    <Panel class="tabs mt10" :title="!!cfg.ssoLoginUrl">
      <template slot="title" v-if="cfg.ssoLoginUrl">
        <div :class="`tab${contentTab==='school'?' active':''}`" @click="setContentTab('school')">校内用户</div>
        <div :class="`tab${contentTab==='external'?' active':''}`" @click="setContentTab('external')">校外用户</div>
      </template>
      <div slot="content">
        <Panel class="login-panel" :title="false">
          <div slot="content" class="content">
            <template v-if="contentTab==='school'">
              <div class="identity-login">
                <div class="iconfont icon-license"></div>
                <div class="identity-login-content-right">
                  <div class="identity-title">统一身份认证系统</div>
                  <div class="identity-sub-title">Identity authentication system</div>
                </div>
              </div>
            </template>
            <template v-if="contentTab==='external'">
              <div class="form-group mt20">
                <div class="form-input-item">
                  <div class="login-mobile"></div>
                  <input class="form-control" v-model="name" placeholder="请填写手机号">
                </div>
              </div>
              <div class="form-group">
                <div class="form-input-item">
                  <div class="login-pwd"></div>
                  <input class="form-control" v-model="pwd" type="password" placeholder="请填写密码">
                </div>
              </div>
              <div class="form-group">
                <div class="form-input-item">
                  <div class="flex-dir-row">
                    <div class="flex-item-auto">
                      <div class="login-captcha"></div>
                      <input class="form-control" v-model="captcha" placeholder="请填写验证码">
                    </div>
                    <div class="captcha-code">
                      <img :src="imgBase64" @click="changeCaptcha">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="btn dark-blue login-btn" @click="doLogin">登录</div>
            <div>
              <div style="text-align: right;margin-top: 10px">
                <span style="margin-top: 10px;color: rgb(23,132,169)" @click="jumpToForgot()">忘记登录密码？</span>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </Panel>
  </div>
</template>
<style lang="scss" scoped>
.identity-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.identity-login-content-right {
  margin-left: 15px;
  text-align: center;
}

.identity-login .icon-license {
  font-size: 50px !important;
}

.identity-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 5px;
}

.identity-sub-title {
  font-size: 15px;
  color: RGBA(174, 174, 174, 1.00);
  margin-top: 5px;
}

.login-panel {
  .panel-title {
    font-size: 24px;
    font-weight: 700;
    color: #1f1f1f;
    margin-bottom: 5px;
  }

  .content {
    margin: 45px 28px;
  }

  .form-control {
    border-radius: 6px;
    border: 2px solid #F5F5F5;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding: 15px 15px 15px 45px;
  }
}

.login-title {
  color: #666;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.form-group {
  margin-bottom: 16px;
}

.form-control::placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #eeeeff;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
}

.form-input-item {
  position: relative;
}

.login-from-title {
  font-size: 22px;
  font-weight: 500;
}

.captcha-code {
  width: 120px;
  margin-left: 10px;
}

.login-bg {
  margin: 0;
  position: absolute;
  top: 0;
  height: 100% !important;
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.login-head {
  background: url("../assets/images/login-head.png");
  background-size: cover;
  height: 221px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-holder {
  width: 92px;
  height: 92px;
  background: rgba(255, 255, 255, .3);
  border-radius: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-holder-inner {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  overflow: hidden;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90%;
}

.logo-project {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
  color: #FFFFFF;
}

.login-mobile, .login-pwd, .login-captcha {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
  left: 16px;
  top: 17px;
}

.login-mobile {
  background-image: url("../assets/images/login-mobile.png");
}

.login-pwd {
  background-image: url("../assets/images/login-pwd.png");
}

.login-captcha {
  background-image: url("../assets/images/login-captcha.png");
}

.login-btn {
  background: #1784A9 !important;
  margin-top: 30px;
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {Toast, MessageBox} from 'mint-ui';
import {mapState} from 'vuex'
import md5 from 'js-md5';

export default {
  components: {
    Panel,
    AppHeader
  },
  data() {
    return {
      name: '',
      pwd: '',
      imgBase64: '',
      captcha: '',
      contentTab: 'school',
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    cfg(state) {
      return state.envs
    }
  }),
  mounted() {
    if (this.cfg.ssoLoginUrl) {
      this.contentTab = 'school'
    } else {
      this.contentTab = 'external';
    }
    this.changeCaptcha()
  },
  methods: {
    jumpToForgot(){
      this.$router.push('/forgot')
    },
    isNull(value, msg) {
      if (!value) {
        Toast(msg)
        return true
      }
      return false
    },
    doLogin() {
      if (this.contentTab === 'school') {
        if(this.cfg.xjdyLoginUrl!=null && this.cfg.xjdyLoginUrl!=undefined){
          location.href = this.cfg.xjdyLoginUrl
        }else {
          location.href = this.cfg.ssoLoginUrl
        }

        return
      }
      if (
          this.isNull(this.name, '用户名不能为空')
          || this.isNull(this.pwd, '密码不能为空')
          || this.isNull(this.captcha, '验证码不能为空')
      ) {
        return
      }
      const that = this
      var md5_pwd= md5('vCzO5jIOLWb57wK0jg0NHcSlX5RbakDT-'+that.pwd)
      this.$http.post(`/portal/account/login`, {
        res: 'login',
        name: that.name,
        pwd: md5_pwd,
        captcha: that.captcha,
        remember: true
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.success) {
          that.$sec.initUserInfo(function () {
            that.$router.push({path: "/"})
          })
        }
      })
    },
    changeCaptcha() {
      const that = this
      this.$http.get(`/common/captcha?v=${Math.random()}`, {
        responseType: 'arraybuffer'
      }).then(response => new Buffer(response, 'binary').toString('base64'))
          .then(data => {
            that.imgBase64 = `data:image/png;base64,${data}`
          });
    },
    setContentTab(tab) {
      this.contentTab = tab;
    },
  }
}
</script>
